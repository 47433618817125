<script>
import Control from "./_Control.vue";
import PostActions from "./_PostActions.vue";
import PostGallery from "./_PostGallery.vue";

export default {
  emits: ["episodePostPlay"],
  data() {
    return {
      messages: [],
    }
  },
  props: {
    canedit: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
    Control,
    PostActions,
    PostGallery,
  },
  beforeUpdate() {
    this.messages = [];
  },
  methods: {
    setMessage: function(el) {
      if(el)
        this.messages.push(el);
    },
    stopAllMessages: function(e, emit) {
      this.messages.forEach((key) => {
        if(key.data.id !== e)
          key.pause();
      });
      if(emit)
        this.$emit('episodePostPlay', e);
    },
  },
  
};
</script>

<template>
  <div class="flex-shrink-0">
    <router-link :to="{ name: 'profile', params: { login: data.profile.login } }">
      <div class="avatar-xs flex-shrink-0 me-1" v-if="!data.profile.urlAvatar">
        <div class="avatar-title rounded-circle">{{ data.profile.login[0].toUpperCase() }}</div>
      </div>
      <img :src="data.profile.urlAvatar" alt="" class="avatar-xs rounded-circle acitivity-avatar" v-if="data.profile.urlAvatar">
    </router-link>
  </div>
  <div class="flex-grow-1 ms-2">

    <router-link :to="{ name: 'profile', params: { login: data.profile.login } }">
      <span class="mb-0 fw-bolder fs-6">
        {{ data.profile.login }}
      </span>
    </router-link>
    <span class="badge bg-danger align-middle ms-2" v-if="data.isGuest">guest</span>
    <p class="text-muted">{{ data.profile.displayName }}</p>

    <div class="card mt-2" v-for="m in data.messages" v-bind:key="m" :id="'postId_' + m.id">
      <img class="card-img-top img-fluid" :src="m.urlImage" alt="" v-if="m.urlImage">
      <div class="card-body">
        <h6 class="card-title mb-3" v-if="m.title">{{ m.title }}</h6>
        <p class="text-muted pre-line" :class="m.urlAudioFile ? 'mb-3' : 'mb-0'" v-if="m.memo">{{ m.memo }}</p>
        <div class="ratio ratio-16x9 mb-2" v-if="m.urlEmbed !== null && m.htmlEmbedCode !== null" v-html="m.htmlEmbedCode"></div>
        <Control :ref="setMessage" :key="m.id" v-bind:data="m" @episode-message-play="stopAllMessages" :canedit="canedit"></Control>

        <div class="mt-2" v-if="m.type === 3">
          <span class="badge bg-secondary badge-gradient-danger">LIVE</span>

          <div class="avatar-group mt-2 mb-2" v-if="m.liveProfiles && m.liveProfiles.length > 0">
            <router-link :to="{ name: 'profile', params: { login: p.login } }" v-for="p in m.liveProfiles" :key="'liveProfile_' + p.id" class="avatar-group-item">
              <img :src="p.urlAvatar" alt="" class="rounded-circle avatar-xs" v-if="p.urlAvatar">
              <div class="avatar-xs" v-if="!p.urlAvatar">
                <div class="avatar-title rounded-circle bg-light text-primary">{{ p.login.toUpperCase()[0] }}</div>
              </div>
            </router-link>
          </div>
          
        </div>
        
        <PostGallery v-bind:data="m" v-if="m.contentGalleries && m.contentGalleries.length > 0"></PostGallery>
        <PostActions v-bind:data="m" :canedit="canedit"></PostActions>
      </div>
    </div>

  </div>
</template>