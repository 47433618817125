<script>
import Slider from "@vueform/slider";
import axios from 'axios';

export default {
  emits: ["episodeMessagePlay"],
  data() {
    return {
      addToPodcast: !this.data.isIgnored,
      cssPlayButtonClass: 'ri-play-line',
      playBeforeMove: false,
      value: 0,
    }
  },
  props: {
    canedit: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
    Slider,
  },
  mounted() {
    var vm = this;
    var id = this.data.id;
    this.$refs.player.onplay = function() {
      vm.$emit('episodeMessagePlay', id, true);
      vm.cssPlayButtonClass = 'ri-pause-line';
    };
    this.$refs.player.onpause = function() {
      vm.cssPlayButtonClass = 'ri-play-line';
    };
    this.$refs.player.addEventListener("timeupdate", this.changedTime, true);
    this.$refs.player.addEventListener("ended", this.endOfRecord, true);
  },
  unmounted() {
    this.pause();
  },
  methods: {
    playRecord: function() {
      if(this.cssPlayButtonClass === 'ri-pause-line') {
        this.$refs.player.pause();
      } else {
        this.$refs.player.play();
      }
    },
    pause: function() {
      if(this.$refs.player !== null)
        this.$refs.player.pause();
    },
    changedTime: function() {
      if(this.$refs.player !== null) {
        this.value = this.$refs.player.currentTime;
        event.preventDefault();
      }
    },
    endOfRecord: function() {
      this.cssPlayButtonClass = 'ri-play-line';
      this.value = 0;
    },
    timeLineMove: function(e) {
      this.$refs.player.currentTime = e;
      this.value = e;
      event.preventDefault();
    },
    changeIgnore(id) { 
      axios.post('/api/post/ignore/' + id, { ignore: this.addToPodcast }, { withCredentials: true }).then(function () {
      }).catch(function () {
      });
    },
    formatTooltip(v) {
      var date = new Date(null);
      date.setSeconds(v);
      return date.toISOString().substring(11, 19);
    },
    beforeMove() {
      this.playBeforeMove = this.$refs.player.paused;
      if(!this.playBeforeMove)
        this.$refs.player.pause();
    },
    afterMove() {
      if(!this.playBeforeMove)
        this.$refs.player.play();
    },
  },
  computed: {
      getDurationFromSeconds: function () {
          if(this.data.duration >= 3600)
              return new Date(this.data.duration * 1000).toISOString().substring(11, 19);
          else
              return new Date(this.data.duration * 1000).toISOString().substring(14, 19);
      }
  }
};
</script>

<template>

  <audio ref="player" preload="none" controls v-show="false">
    <source :src="data.urlAudioFile" type="audio/mpeg">
    Your browser does not support the audio element.
  </audio>

  <div class="d-flex align-items-center" v-if="data.urlAudioFile">
    <div class="flex-shrink-0 me-3">
      <div class="avatar-xs">
        <div class="avatar-title bg-light rounded-circle text-muted fs-16">
          <a @click="playRecord" class="btn btn-primary btn-sm rounded-pill">
            <i :class="cssPlayButtonClass"></i>
          </a>
        </div>
      </div>
    </div>
    
    <div class="flex-grow-1 me-3">
      <Slider v-model="value" v-bind:tooltips="true" :max="data.duration" show-tooltip="focus" @change="timeLineMove" :format="formatTooltip" class="primary-slider" @start="beforeMove" @end="afterMove" />
    </div>
    <div class="flex-shrink-0 me-2">
      <span>{{ getDurationFromSeconds }}</span>
    </div>
    <div class="flex-shrink-0 me-2" v-if="canedit">
      <div class="form-check form-switch mt-2 mb-2" title="Add to podcast" data-toggle="tooltip">
        <input class="form-check-input" type="checkbox" role="switch" v-model="addToPodcast" :checked="!data.isIgnored" @change="changeIgnore(data.id)">
      </div>
    </div>
  </div>
</template>
